

.jagatbandhutti{
    background-color: rgb(112, 179, 190);
}

/* .biman h4{
    decoration: none;
} */

