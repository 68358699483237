.navbutton{
    background-color: #72033e; /* Green */
  border: none;
  color: white;
  padding: 6px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
}

.collegeazimganj{
  background-color: rgb(255, 240, 240);
}