.marquuecolor{
    background-color: rgb(0, 50, 125);
}




.btn-grad13 {
    background-image: linear-gradient(to right, #c09544 0%, #805510  51%, #0f2b58  100%);
    margin: 0px;
    padding: 9px 29px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 2px;
    display: block;
  }
  
  .btn-grad13:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .btn-grad131 {
    background-image: linear-gradient(to right, #8d5f0b 0%, #573807  51%, #ef0505  100%);
    margin: 0px;
    padding: 9px 15px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 2px;
    display: block;
  }
  
  .btn-grad131:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .jagatbandhu{
    height:  540px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/tJcvnQzT/Whats-App-Image-2024-06-07-at-1-03-37-PM.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }